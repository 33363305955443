export const auth = {
	signin: baseUrl + '/login',
	register: baseUrl + '/api/v1/register',
	forgot_password: baseUrl + '/password/email',
	reset_password: baseUrl + '/password/reset',
	send_verification: baseUrl + '/api/v1/send_verification',
}

export const user = {
	unique_email: baseUrl + '/api/v1/user/check-email',
	unique_phone: baseUrl + '/api/v1/user/check-phone',
}

export const otp = {
	verify: baseUrl + '/api/v1/otp/verify',
	resend: baseUrl + '/api/v1/otp/resend'
}

export const promocode = {
	verify: baseUrl + '/api/v1/verify-freelance-sa'
}

export const applepay = {
	verify_merchant: baseUrl + '/api/v1/apple-pay/validate-merchant',
	authorizePayment: (uuid) => `${baseUrl}/api/v1/apple-pay/authorize/${uuid}`,
	authorizeInvoicePayment: (uuid) => `${baseUrl}/api/v1/apple-pay/invoice-authorize/${uuid}`,
}

export const invoice = {
	pay_in_cash: baseUrl + '/api/v1/invoice-payments/pay-in-cash',
	bank_transfer: baseUrl + '/api/v1/invoice-payments/bank-transfer',
}

export const payfort = {
	redirectUrl: baseUrl + '/api/v1/get-redirect-url',
}

export const tap = {
	charge: baseUrl + '/tap/charge',
	order_charge: baseUrl + '/tap/order-charge',
	redirectUrl: baseUrl + '/tap/redirect-url',
	orderRedirectUrl: baseUrl + '/tap/order-redirect-url',

	userCharge: baseUrl + '/tap-user/charge',
	userRedirectUrl: baseUrl + '/tap-user/redirect-url',
}

export const signupV2 = {
	verify:baseUrl+'/api/v2/verify-otp-v2',
	send:baseUrl+'/api/v2/signup-otp-v2',
	signup:baseUrl+'/api/v2/signup-v2'
}

export const signin = {
	index:baseUrl+'/signin',
}

export const resetPasswordV2 = {
	index:baseUrl+'/api/v2/reset-password-v2',
	send:baseUrl+'/api/v2/resetpassword-otp-v2'
}
